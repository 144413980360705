import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import LocationBlocks from "./contact/LocationBlocks"

import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import SocialReviews from "../components/SocialReviews/SocialReviews"
import { graphql } from "gatsby"

import locationMarker from "./contact/location-marker.svg"
import locationMarkerWhite from "./contact/location-marker-white.svg"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

import { getPostObj } from "../utils/utils"

const ContactUsPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  var locationHours = data.allLocationsJson.nodes[0].locationData.locationHours

  var locations = data.allLocationsJson.nodes

  return (
    <SharedStateProvider>
      <Layout
        className={`contact-us ${language === "es" ? "es" : null}`}
        layoutClass={"contact-fab"}
        language={language}
      >
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />

        {post.googleMapsBackground.hasMap ? (
          <div className="contact-top-wrapper">
            <ImageMeta
              className="splash"
              cloudName="nuvolum"
              publicId={post.googleMapsBackground.splashPublicId}
              width="auto"
            ></ImageMeta>

            {post.pinTextLineOne && (
              <a href={post.googleMapsBackground.pinLink} target="_blank">
                <div className="marker-heading">
                  <img
                    className="marker"
                    src={locationMarker}
                    alt="Location Marker"
                  />
                  <h5>
                    {post.googleMapsBackground.pinTextLineOne}
                    {/* <br />
              {post.googleMapsBackground.pinTextLineTwo} */}
                  </h5>
                </div>
              </a>
            )}
            <div className="contact-top-headings">
              <h1>{post.heading}</h1>
            </div>
          </div>
        ) : (
          <div className="columns top-section white-back">
            <div className="column is-5"></div>
            <div className="column">
              <h1 style={{ marginTop: "0" }}>{post.heading}</h1>
            </div>
          </div>
        )}
        <div className="body-section contact-us-body">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column is-10">
              <div className="is-hidden-tablet" style={{ marginTop: "40px" }}>
                <LocationBlocks language={language} locations={locations} />
              </div>

              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              ></div>
              {language !== "es" && (
                <div className="contact-form-section">
                  <div className="inline-block">
                    <h5>Send Us a Message</h5>{" "}
                    <span className="required-fields">
                      <span className="required-asterisk">*</span> Required
                      Fields
                    </span>
                  </div>
                  <Seamless id="33e2c0a55844f125ec7f440933dfd39c" />
                </div>
              )}
            </div>
            <div className="column is-1"></div>
            <div className="column is-narrow contact-info is-hidden-mobile is-7">
              {locations.length > 1 && (
                <LocationBlocks language={language} locations={locations} />
              )}
              {locations.length === 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                    <a href={post.googleMapsBackground.pinLink} target="_blank">
                      {post.sidebar.address.street}
                      <br />
                      {post.sidebar.address.cityStateZip}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                    <a href={post.sidebar.phone.phonePlusFormat}>
                      {post.sidebar.phone.phoneText}
                    </a>
                  </div>

                  <div className="contact-sidebar-block">
                    <GeneralHours
                      language={language}
                      contact={true}
                      locationHours={locationHours}
                    />
                  </div>
                  <div className="contact-reviews">
                    <SocialReviews
                      google
                      facebook
                      googleHeading
                      language={language}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="column is-1"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          hasMap
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    dataJson(optionsItem: { eq: "siteVariables" }) {
      mainLocation
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
        }
      }
    }
  }
`

export default ContactUsPage
